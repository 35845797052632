/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

import { Container } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

const SystemVulnerabilityReportTemplate = ({ padding }) => {
  const [pt, pb] = useContainerPadding(padding);
  return (
    <Container containerSx={{ pt, pb }}>
      <Box
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://hackerone.com/d0ab9070-d14a-4a82-a509-5102b548b399/embedded_submissions/new" frameborder="0" title="Submit Vulnerability Report" style="border: none; width: 100%; height: 1000px;" />`,
        }}
      />
    </Container>
  );
};

export default SystemVulnerabilityReportTemplate;
